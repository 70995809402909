import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"

//import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { MDXProvider } from '@mdx-js/react'

import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'

import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { previous, next, _id, slug } = pageContext
  const article = data.strapiArticle

  /* function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million 
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  } */

  let disqusConfig = {
    url: `${data.site.siteMetadata.siteUrl + '/' + article.slug}`,
    identifier: article.id,
    title: article.title,
  }

  const date = new Date(article.updatedAt)


  useEffect(() => {
    const xhttp = new XMLHttpRequest();
    xhttp.open('POST', 'https://coderescue.ml/api/post/' + slug, true)
    xhttp.send();

  }, [])


  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={article.title}
        description={article.meta_description}
        slug={article.slug}
        image={article.hero_image.url}
      />
      <div className='article-wrapper'>
        <div className='article-image-wrapper'>
          <img src={article.hero_image.url + '?tr=w-1200'} alt={article.hero_image.alternativeText} className='article-image'></img>
          <div className='article-details'>
            <h1 className='article-title'>{article.title}</h1>
            <div className='featured-post-date'>
              <small className='date'>{date.toLocaleDateString()}</small>
              {/* <small className='date'>|</small>
              <small className='date'>Views: {numFormatter(article.views)}</small>
              <small className='date'>|</small> */}
              <small className='date'>{article.childStrapiArticleContent.childMdx.timeToRead} min read</small>
            </div>
          </div>
        </div>
        <div className='article-content-wrapper'>
          <div className='article-content-container'>
            <article className='article-content'>
              {/* <CommentCount config={disqusConfig} placeholder={'...'} /> */}
              <MDXProvider>
                <MDXRenderer>{article.childStrapiArticleContent.childMdx.body}</MDXRenderer>
              </MDXProvider>
            </article>
            {/* <div className='article-sidewidgets-wrapper'>
              <div className='current-category-posts-wrapper'>
                <h4>Posts in this category...</h4>
                <div className='current-category-posts'>
                  <h5>Post title</h5>
                  <small>Date</small>
                </div>
              </div>
              <div className='newsletter-wrapper'>

              </div>

              <div className='similar-posts-wrapper'>

              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className='navigation-wrapper'>

      </div>

      {/* <Disqus config={disqusConfig} /> */}

    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: {eq: "published"}) {
      updatedAt
      slug
      strapiId
      title
      meta_description
      id
      views
      hero_image {
        alternativeText
        url
        caption
      }
      childStrapiArticleContent {
            childMdx {
              body
              timeToRead
          }
    }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
